<template lang="pug">
.box__body
    .flex
        .card(style="width: 280px;")
            .field.w-full.mb-6
                .field__label
                    label.block.text-sm.font-bold(for="fieldSelect") Name
                .field__form
                    input.field-style(type="text" v-model="name" placeholder="Write a name here..")
                    
            .field.w-full.mb-6
                .field__label
                    label.block.text-sm.font-bold(for="fieldSelect") Age Range
                .field__form.flex.items-center.gap-4
                    input.field-style.text-center(type="number" placeholder="start" min="0" v-model="age_min" style="padding-left:0px !important; padding-right:0px !important;")
                    span to
                    input.field-style.text-center(type="number" placeholder="end" min="0" v-model="age_max" style="padding-left:0px !important; padding-right:0px !important;")
            
            .field.w-full.mb-6
                .field__label
                    label.block.text-sm.font-bold(for="fieldSelect") Gender
                .flex.items-center.gap-4
                    .ui-radio.w-full
                        input(type="radio" name="gender" value="L" v-model="gender")
                        .style-radio
                            .block
                                .radio
                            .flex.items-center.justify-between.w-full
                                span Male
                    .ui-radio.w-full
                        input(type="radio" name="gender" value="P" v-model="gender")
                        .style-radio
                            .block
                                .radio
                            .flex.items-center.justify-between.w-full
                                span Female
            .field.w-full.mb-6
                .field__label
                    label.block.text-sm.font-bold(for="fieldSelect") Province
                .relative.w-full
                    template(v-if="loading_province === true")
                        Spinner(show="true" size="24")
                    template(v-else)
                        select.field-style(v-model="province")
                            option(value="") All
                            template(v-if="search_by_name_filter_province.data && search_by_name_filter_province.data.length > 0")
                                option(v-for="province in search_by_name_filter_province.data" :value="province.id + '_' + province.name") {{ province.name }}
                        .field-icon
                            IconSet(
                                type="chevron-down"
                            )

            .field.w-full.mb-6
                .field__label
                    label.block.text-sm.font-bold(for="fieldSelect") City
                .relative.w-full
                    template(v-if="loading_city === true")
                        Spinner(show="true" size="24")
                    template(v-else)
                        select.field-style(v-model="city")
                            option(value="") All
                            template(v-if="search_by_name_filter_city.data && search_by_name_filter_city.data.length > 0")
                                option(v-for="city in search_by_name_filter_city.data" :value="city.id + '_' + city.name") {{ city.name }}
                        .field-icon
                            IconSet(
                                type="chevron-down"
                            )
            .field.w-full.mb-6
                .field__label
                    label.block.text-sm.font-bold(for="fieldSelect") District
                .relative.w-full
                    template(v-if="loading_district === true")
                        Spinner(show="true" size="24")
                    template(v-else)
                        select.field-style(v-model="district")
                            option(value="") All
                            template(v-if="search_by_name_filter_district.data && search_by_name_filter_district.data.length > 0")
                                option(v-for="district in search_by_name_filter_district.data" :value="district.name") {{ district.name }}
                        .field-icon
                            IconSet(
                                type="chevron-down"
                            )

            .flex.flex-col.gap-4
                button.btn-default(@click="openWatchlist(true)") Watchlist: 100
                    IconSet(type="chevron-down")
                button.btn-primary.w-full(
                    v-if="(checkFeatureByAcl('deep_void', null) || checkFeatureByAcl('dcd', null))"
                    @click="getData(true)"
                ) Search Target
                button.btn-default.w-full(@click="reset()") Reset


        .card.card--table.card--no-bg.flex-grow.relative
            .flex.items-center.justify-center.absolute.w-full.h-full(v-if="!search_by_name.data")
                p.text-center(style="max-width:280px") Please fill in the data form on the side to display a more specific profile's target
            .card--table__body(v-else)
                .box-table.box-table--target
                    table.table
                        thead.m-hide
                            tr
                                th(v-for="(th, index) in tableThead" :key="index")
                                    | {{ th.name }}
                        tbody
                            tr(v-if="load_data_status_search_by_name === 'loading'")
                                td.text-center(:colspan="tableThead.length")
                                    .flex.flex-wrap.items-center.justify-center
                                        Spinner(show="true" size="48")

                            template(v-else-if="search_by_name.data && search_by_name.data.length > 0")
                                tr(v-for="(data, index) in search_by_name.data" :key="index")
                                    td.text-sm {{ data._source.nama ? data._source.nama : '-' }}
                                    td.text-sm {{ data._source.nik ? data._source.nik : '-' }}   
                                    td.text-sm {{ data._source.tgl_lahir ? data._source.tgl_lahir.substring(0, 10) : '-' }}
                                    td.text-sm.text-center {{ data._source.age ? data._source.age : '-' }}
                                    td.text-sm.text-center {{ data._source.jenis_kelamin ? data._source.jenis_kelamin : '-' }}
                                    td.text-sm {{ data._source.kab ? data._source.kab : '-' }}
                                    td.text-sm {{ data._source.kec ? data._source.kec : '-' }}
                                    td.text-sm {{ data._source.alamat ? data._source.alamat : '-' }}, {{ data._source.kec ? data._source.kec : '-' }}, {{ data._source.kab ? data._source.kab : '-' }}, {{ data._source.pro ? data._source.pro : '-' }}
                            tr(v-else)
                                td.text-center(:colspan="tableThead.length") No Data Available


                .box-table-footer(v-if="load_data_status_search_by_name !== 'loading'")
                    .pagination
                        vue-ads-pagination(
                            :total-items="total_data"
                            :items-per-page="items_per_page"
                            :page="page"
                            :max-visible-pages="5"
                        )
                            template(slot="buttons" slot-scope="props")
                                vue-ads-page-button(
                                    v-for="(button, key) in props.buttons"
                                    :key="key"
                                    v-bind="button"
                                    :class="{'bg-yellow-dark': button.active}"
                                    @page-change="page = button.page"
                                    @range-change="start = button.start; end = button.end"
                                )

    //- popup 
    .popup-layer.is-show(v-if="popUpWatchlist")
        .popup-container
            .popup-container__header
                .flex.items-center
                    h2.text-xl WatchList

                button.btn-icon-40(
                    @click="openWatchlist(false)"
                )
                    IconSet(type="close")

            .popup-container__body
                .mb-2.pb-2
                    p hello
</template>

<script>
import IconSet from '@/components/IconSet.vue'
import { mapState } from 'vuex';
import { encrypt,decrypt } from '@/util/crypto';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import '../../../node_modules/vue-ads-pagination/dist/vue-ads-pagination.css';
import Spinner from '@/components/Spinner.vue';

export default {
    components: {
        IconSet,
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,        
    },

    data: function() {
        return {
            tableThead: [
                {
                    name: 'Name',
                },{
                    name: 'NIK',
                },{
                    name: 'Birth Date',
                },{
                    name: 'Age',
                },{
                    name: 'Gender',
                },{
                    name: 'City',
                },{
                    name: 'District',
                },{
                    name: 'Address',
                },
            ],
            keyword: '',
            data_result: [],

            // pagination
            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            // for filter
            name: '',
            gender: '',
            province: '',
            city: '',
            district: '',
            year_of_birth_data: '',
            age_min: '',
            age_max: '',

            loading_province: false,
            loading_city: false,
            loading_district: false,

            // for data select options
            provinces: [],
            cities: [],
            districts: [],

            filter_gender: ['L', 'P'],
            timeout: null,
            popUpWatchlist: false,
            searchResult: false,
            data: [
                {
                    name: 'Maman Kurniawan',
                    nik: '0001234567890120',
                    birthdate: '21/08/1993',
                    age: '29',
                    gender: 'M',
                    city: 'Bandung',
                    district: 'Buah Batu',
                    address: '4517 Washington Ave. Manchester, Kentucky 39495',
                },
                {
                    name: 'Maman Kurniawan',
                    nik: '0001234567890120',
                    birthdate: '21/08/1993',
                    age: '29',
                    gender: 'M',
                    city: 'Bandung',
                    district: 'Buah Batu',
                    address: '4517 Washington Ave. Manchester, Kentucky 39495',
                },
                {
                    name: 'Maman Kurniawan',
                    nik: '0001234567890120',
                    birthdate: '21/08/1993',
                    age: '29',
                    gender: 'M',
                    city: 'Bandung',
                    district: 'Buah Batu',
                    address: '4517 Washington Ave. Manchester, Kentucky 39495',
                },
                {
                    name: 'Maman Kurniawan',
                    nik: '0001234567890120',
                    birthdate: '21/08/1993',
                    age: '29',
                    gender: 'M',
                    city: 'Bandung',
                    district: 'Buah Batu',
                    address: '4517 Washington Ave. Manchester, Kentucky 39495',
                },
                {
                    name: 'Maman Kurniawan',
                    nik: '0001234567890120',
                    birthdate: '21/08/1993',
                    age: '29',
                    gender: 'M',
                    city: 'Bandung',
                    district: 'Buah Batu',
                    address: '4517 Washington Ave. Manchester, Kentucky 39495',
                },
                {
                    name: 'Maman Kurniawan',
                    nik: '0001234567890120',
                    birthdate: '21/08/1993',
                    age: '29',
                    gender: 'M',
                    city: 'Bandung',
                    district: 'Buah Batu',
                    address: '4517 Washington Ave. Manchester, Kentucky 39495',
                },
                {
                    name: 'Maman Kurniawan',
                    nik: '0001234567890120',
                    birthdate: '21/08/1993',
                    age: '29',
                    gender: 'M',
                    city: 'Bandung',
                    district: 'Buah Batu',
                    address: '4517 Washington Ave. Manchester, Kentucky 39495',
                },
                {
                    name: 'Maman Kurniawan',
                    nik: '0001234567890120',
                    birthdate: '21/08/1993',
                    age: '29',
                    gender: 'M',
                    city: 'Bandung',
                    district: 'Buah Batu',
                    address: '4517 Washington Ave. Manchester, Kentucky 39495',
                },
                {
                    name: 'Maman Kurniawan',
                    nik: '0001234567890120',
                    birthdate: '21/08/1993',
                    age: '29',
                    gender: 'M',
                    city: 'Bandung',
                    district: 'Buah Batu',
                    address: '4517 Washington Ave. Manchester, Kentucky 39495',
                },
                {
                    name: 'Maman Kurniawan',
                    nik: '0001234567890120',
                    birthdate: '21/08/1993',
                    age: '29',
                    gender: 'M',
                    city: 'Bandung',
                    district: 'Buah Batu',
                    address: '4517 Washington Ave. Manchester, Kentucky 39495',
                },
            ]
        }
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapState('searchbyName',[
            'load_data_status_search_by_name',
            'search_by_name',
            'search_by_name_filter_province',
            'search_by_name_filter_city',
            'search_by_name_filter_district',
        ]),
        route_name() {
            return this.$route.name;
        },
        key() {
            return this.$route.params.key;
        },
        isKey() {
            // return this.key!==undefined ? true : false;
            return this.key ? true : false;
        },
        year_of_birth: {
            get() {
                let data = this.year_of_birth_data;
                // if (this.year_of_birth_data === '') {
                //     const finish_year = new Date().getFullYear();
                //     data = finish_year - 16;
                // }

                return data;
            },
            set(v) {
                this.year_of_birth_data = v;
            },
        },
        year_of_birth_options() {
            const start_year = 1900;
            const finish_year = new Date().getFullYear();

            let options = [];
            let count_year = finish_year;

            for (var i = (finish_year + 1); i >= (start_year + 1); i--) {
                options.push(count_year--);
            }

            return options;
        },
    },
    watch: {
        route_name(newData, oldData) {
            if (newData === 'profiling-Index') {
                if (oldData !== 'profiling-Index') {
                    this.items_per_page = 10;
                    this.page = 0;
                    // this.keyword = '';
                    this.$store.commit('searchbyName/setSearchByName', {});
                }
                // this.getData();
            }
        },
        key() {
            // if (this.route_name === 'profiling-Index')
            this.name = '';
            this.gender = '';
            this.province = '';
            this.city = '';
            this.district = '';
            // this.year_of_birth_data = '';
            this.year_of_birth = '';
            this.age_min = '';
            this.age_max = '';

            // this.page = 0;
            this.getData(true);

            this.first_load_travel_history = true;
            this.first_load_marketplace = true;
            this.first_load_university = true;
            this.first_load_personal_identity = true;
        },
        isKey() {},
        breadcrumb() {},

        search_by_name(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
                // this.page = (parseInt(newData.current_page) - 1);
            }
        },
        total_data() {
            // this.pagination();
        },
        items_per_page() {
            this.getData();
            // this.pagination();
        },
        page() {
            this.getData(true);
        },
        // filter
        // search_by_name_filter_province(newData) {
        //     console.log(newData)
        // },
        // search_by_name_filter_city(newData) {
        //     console.log(newData)
        // },
        search_by_name_filter_district() {
            // console.log(newData)
        },
        gender() {
            // this.filterBy();
            // this.getData();
        },
        async province(newData) {
            // this.filterBy();
            this.city = '';
            this.district = '';
            this.$store.commit('searchbyName/setSearchByNameFilter', ['city', {}]);
            this.$store.commit('searchbyName/setSearchByNameFilter', ['district', {}]);
            // this.getData();

            // ----
            if (newData.length > 0 && Object.keys(this.search_by_name_filter_city).length === 0) {
                // this.showLoading(true);
                this.loading_city = true;
                await this.$store.dispatch('searchbyName/getSearchByNameFilter', ['city', newData.split('_')[0]])
                .then(() => {
                    // this.showLoading(false);
                    this.loading_city = false;
                });
            }
        },
        async city(newData) {
            // this.filterBy();
            this.district = '';
            this.$store.commit('searchbyName/setSearchByNameFilter', ['district', {}]);
            // this.getData();

            // ----
            if (newData.length > 0 && Object.keys(this.search_by_name_filter_district).length === 0) {
                // this.showLoading(true);
                this.loading_district = true;
                await this.$store.dispatch('searchbyName/getSearchByNameFilter', ['district', newData.split('_')[0]])
                .then(() => {
                    // this.showLoading(false);
                    this.loading_district = false;
                });
            }
        },
        district() {
            // this.filterBy();
            // this.getData();
        },
        name(){

        },
        year_of_birth() {
            // this.filterBy();
            // this.getData();
        },
        age_min() {
            // if (newData.length !== 0 && this.age_max.length !== 0) {
                // clearTimeout(this.timeout);
            
                // var self = this;
                // this.timeout = setTimeout(function () {
                //     self.getData();
                // }, 650);
            // }
        },
        age_max() {
            // if (newData.length !== 0 && this.age_min.length !== 0) {
                // clearTimeout(this.timeout);
            
                // var self = this;
                // this.timeout = setTimeout(function () {
                //     self.getData();
                // }, 650);
            // }
        },
    },
    methods: {
        encryptNik(nik) {
            return encrypt(nik + '&&nik');
        },
        calculateAge(dob) {

            dob = dob.split('-');
            let date = new Date(parseInt(dob[0]), (parseInt(dob[1]) - 1), parseInt(dob[2]));
            
            var diff_ms = new Date() - date.getTime();
            var age_dt = Math.floor(diff_ms / (1000 * 60 * 60 * 24 * 365.2425));

            return age_dt;
        },
        async getData(filter=false) {
            // console.log('getData++++++++++')
            // console.log(filter)
            if (this.route_name === 'profiling-Index' ) {
                // if (this.isKey) {

                if (filter){
                    // this.page = 0;

                    let decrypt_data = decrypt(this.key);
                    decrypt_data = decrypt_data.split('&&');

                    var key = decrypt_data[0];
                    // var type = decrypt_data[1];

                    this.keyword = key;

                    // gender='', province='', city='', district='', year_of_birth='', age_range=''
                    this.showLoading(true);
                    await this.$store.dispatch('searchbyName/getSearchByName', [this.name, this.items_per_page, (this.page + 1), this.gender, (this.province.length > 0 ? this.province.split('_')[1] : ''), (this.city.length > 0 ? this.city.split('_')[1] : ''), this.district, this.year_of_birth, (this.age_min.length > 0 && this.age_max.length > 0 ? this.age_min + '-' + this.age_max : '')])
                    .then(() => {
                        this.showLoading(false);
                    });
                } else {
                // get filter params

                    this.loading_province = true;
                    await this.$store.dispatch('searchbyName/getSearchByNameFilter', ['province'])
                    .then(() => {
                        this.showLoading(false);
                        this.loading_province = false;
                    });
                }

                // if (this.province.length > 0 && Object.keys(this.search_by_name_filter_city).length === 0) {
                //     this.showLoading(true);
                //     await this.$store.dispatch('getSearchByNameFilter', ['city', this.province.split('_')[0]])
                //     .then(() => {
                //         this.showLoading(false);
                //     });
                // }

                // if (this.city.length > 0 && Object.keys(this.search_by_name_filter_district).length === 0) {
                //     this.showLoading(true);
                //     await this.$store.dispatch('getSearchByNameFilter', ['district', this.city.split('_')[0]])
                //     .then(() => {
                //         this.showLoading(false);
                //     });
                // }
            
                this.pagination();
                    
                // } else {
                //     this.$store.commit('searchbyName/setSearchByName', []);
                // }                
            }
        },
        reset() {

            this.$swal.fire({
                icon: 'warning',
                title: 'Are you sure you want to reset?',
                // text: 'Role : ' + name,
                confirmButtonText: 'Yes!',
                confirmButtonColor: '#DD6B55',
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then((result) => {
                // console.log('on result');
                if (result.isConfirmed) {
                    this.name = '';
                    this.gender = '';
                    this.province = '';
                    this.city = '';
                    this.district = '';
                    // this.year_of_birth_data = '';
                    this.year_of_birth = '';
                    this.age_min = '';
                    this.age_max = '';

                }
            });

        },
        // pagination
        pageChange(page) {
            this.page = page;
        },
        rageChange(start, end) {
            this.start = start;
            this.end = end;
        },
        sizeChange() {
            this.page = 0;
        },
        pagination() {
            // update fontawesome to iconfont
            var paginationButtonPreview = document.getElementsByClassName("fa-angle-left");

            if (paginationButtonPreview[0]) {
                var classListPrev = paginationButtonPreview[0].classList;

                if (Object.values(classListPrev).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonPreview[0].classList.add('iconfont');
                    paginationButtonPreview[0].classList.add('iconleft');
                    paginationButtonPreview[0].style.fontSize = '12px';
                }
            }

            var paginationButtonNext = document.getElementsByClassName("fa-angle-right");

            if (paginationButtonNext[0]) {
                var classListNext = paginationButtonNext[0].classList;

                if (Object.values(classListNext).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonNext[0].classList.add('iconfont');
                    paginationButtonNext[0].classList.add('iconright');
                    paginationButtonNext[0].style.fontSize = '12px';
                }
            }
        },
        showLoading(show=true) {
            if (show) {
                this.$swal.fire({
                    title: '',
                    html: '<div class="save_loading"><svg viewBox="0 0 140 140" width="140" height="140"><g class="outline"><path d="m 70 28 a 1 1 0 0 0 0 84 a 1 1 0 0 0 0 -84" stroke="rgba(0,0,0,0.1)" stroke-width="4" fill="none" stroke-linecap="round" stroke-linejoin="round"></path></g><g class="circle"><path d="m 70 28 a 1 1 0 0 0 0 84 a 1 1 0 0 0 0 -84" stroke="#71BBFF" stroke-width="4" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-dashoffset="200" stroke-dasharray="300"></path></g></svg></div><div><h4>Loading...</h4></div>',
                    showConfirmButton: false,
                    allowOutsideClick: false
                });

            } else {
                this.$swal.close();
            }
        },
        openWatchlist(open = true) {
            if(open) {
                EventEmit.$emit('overflowHidden', true);
            } else {
                EventEmit.$emit('overflowHidden', false);
            }
            this.popUpWatchlist = !this.popUpWatchlist
        },
    },
    mounted() {
        this.getData();
    },    
}
</script>